<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect"></a-park-type-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_Time')">
                <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchNightParkList"
                :loading="loading"
                >查询
              </el-button>
              <el-button type="info" icon="el-icon-delete" @click="clearData()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>

          <!-- <el-form-item :label="$t('searchModule.date')"
                      prop="dateType"
                      style="width:200px;">
          <el-select v-model.trim="formInline.dateType"
                    filterable
                    size="15"
                    style="width:100px;"
                    >
            <el-option label="日"
                      value="day">日</el-option>
            <el-option label="月"
                      value="month">月</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              :defalutDate = "defaultTime"
              :type = "formInline.dateType == 'day' ? 'daterange' : 'monthrange'"
              :valueFormat = "formInline.dateType == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
            />
        </el-form-item> -->
          <!-- <el-date-picker v-model="startTime"
                        :picker-options="pickerOptions"
                        type="date"
                        placeholder="选择日期"
                        v-show="formInline.dateType == 'day'"
                        style="margin-right: 10px;"></el-date-picker>
        <span v-show="formInline.dateType == 'day'">至</span>
        <el-date-picker v-model="endTime"
                        :picker-options="pickerOptions"
                        type="date"
                        placeholder="选择日期"
                        v-show="formInline.dateType == 'day'"
                        style="margin-right: 10px;margin-left: 10px;"></el-date-picker>
        <el-date-picker v-model="startTime"
                        :picker-options="pickerOptions"
                        type="month"
                        placeholder="选择月"
                        v-show="formInline.dateType == 'month'"
                        style="margin-right: 10px;"></el-date-picker>
        <span v-show="formInline.dateType == 'month'">至</span>
        <el-date-picker v-model="endTime"
                        :picker-options="pickerOptions"
                        type="month"
                        placeholder="选择月"
                        v-show="formInline.dateType == 'month'"
                        style="margin-right: 10px;margin-left: 10px;"></el-date-picker> -->
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportExcelReport()"
                v-if="$route.meta.authority.button.export"
                :loading="loading"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="content">
        <el-row :gutter="20" class="marginTB20">
          <el-col :span="12">
            <!-- 会员支付占比 -->
            <Carbinding
              class="graphShadow paddingT10 paddingLR20 paddingB10"
              :pieData="pieList"
            ></Carbinding>
          </el-col>
          <el-col :span="12">
            <!-- 会员线上支付占比 -->
            <CarAccounted
              class="graphShadow paddingT10 paddingLR20 paddingB10"
              :rightList="pieList"
            ></CarAccounted>
          </el-col>
        </el-row>
        <!--折线图-->
        <PayChart
          :nightParktableData="tableData"
          :type="type3"
          class="graphShadow paddingT10 paddingLR20 paddingB10 marginTB20"
        ></PayChart>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingT10 paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// import timeRangePick from "@/components/timePicker"
import { getZeroOrLastDateTime } from "@/common/js/utils";
// 折线图
import PayChart from "./echarts/PayChart1.vue";
import Carbinding from "./echarts/Carbinding1.vue";
import CarAccounted from "./echarts/CarAccounted1.vue";
import { exportExcelNew, dateFormat, formatWeekDate } from "../../../../common/js/public.js";

export default {
  // 停车分析
  name: "Parkinganalysis",
  data() {
    const startTime = getZeroOrLastDateTime("yyyy-MM-dd", -31);
    const endTime = getZeroOrLastDateTime("yyyy-MM-dd", -1);
    return {
      defaultTime: [startTime, endTime],
      selectkeys: ["date", "month", "week", "year"],
      rightList: [],
      tableData: [],
      pieList: [],
      loading: false,
      // pickerOptions: {
      //   disabledDate (time) {
      //     return time.getTime() > endTime;
      //   }
      // },
      formInline: {
        startTime: "",
        endTime: "",
        dateType: "day",
      },
      // 折线图样式
      type3: "c3",
      type2: "c2",
      tableCols: [
        {
          prop: "_dataDate",
          label: "时间",
          width: "",
        },
        {
          prop: "onlinePayCnt",
          label: "线上支付订单(个)",
          // formatter: (row) => {
          //   if (row.onlinePayMoney) {
          //     return (row.onlinePayMoney / 100).toFixed(2);
          //   } else {
          //     return "0.00";
          //   }
          // },
        },
        {
          prop: "onlinePayMoney",
          label: "线上支付金额(元)",
          formatter: (row) => {
            if (row.onlinePayMoney) {
              return (row.onlinePayMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "offlinePayCnt",
          label: "线下支付订单(个)",
          // formatter: (row) => {
          //   if (row.offlinePayMoney) {
          //     return (row.offlinePayMoney / 100).toFixed(2);
          //   } else {
          //     return "0.00";
          //   }
          // },
        },
        {
          prop: "offlinePayMoney",
          label: "线下支付金额(元)",
          width: "",
          formatter: (row) => {
            if (row.offlinePayMoney) {
              return (row.offlinePayMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualPay",
          label: "支付总额(元)",
          formatter: (row) => {
            if (row.actualPay) {
              return ((row.actualPay / 100).toFixed(2) || 0)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "shouldPay",
          label: "应收总额(元)",
          formatter: (row) => {
            if (row.shouldPay) {
              return ((row.shouldPay / 100).toFixed(2) || 0)
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
            } else {
              return "0.00";
            }
          },
        },
      ],
    };
  },
  methods: {
    clearData() {
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
    },
    getDateType() {
      let dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      let val = "";
      switch (dateType) {
        case 1:
          val = "day";
          break;
        case 5:
          val = "week";
          break;
        case 2:
          val = "month";
          break;
        case 3:
          val = "year";
          break;
        default:
          break;
      }
      return val;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 查询夜间停车列表
    searchNightParkList() {
      // let flag = this.showLog();
      // if (flag) {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/memberStatistic/paymentTendency", {
          data: {
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
          },
        })
        .then((res) => {
          if (res.value[0] != null) {
            this.tableData = res.value;
            // 周粒度需要单独处理

            this.tableData.forEach((item) => {
              if (this.$refs.datePicker.getDateType() == 3) {
                if (item.dataDate) {
                  item._dataDate = formatWeekDate(item.dataDate);
                } else {
                  item._dataDate = item.dataDate;
                }
              } else {
                item._dataDate = item.dataDate;
              }
            });
            console.log(this.tableData, "this.tableData");
          } else {
            this.tableData = [
              {
                allPayCnt: 0,
                dataDate: 0,
                offlinePayMoney: 0,
                onlinePayMoney: 0,
              },
            ];
          }
        })
        .finally(() => {
          this.loading = false;
        });
      // }
      this.piechart();
    },
    // 饼图
    piechart() {
      // let flag = this.showLog();
      // if (flag) {
      this.$axios
        .get("/acb/2.0/memberStatistic/paymentStatistics", {
          data: {
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
          },
        })
        .then((res) => {
          if (res.value) {
            this.pieList = res.value;
            this.rightList = res.value;
          } else {
            this.pieList = [];
            this.rightList = [];
          }
        });
      // }
    },
    // 导出
    exportExcelReport() {
      // console.log(this.formInline.dataDate);
      // this.formInline.dataDate = this.formInline.dataDate ? this.formInline.dataDate : this.dateFormat(new Date(), 'yyyy-MM')
      // exportExcelNew('/acb/2.0/debtStatistics/arrearagehistoryExport', this.formInline, 'post')
      // let flag = this.showLog();
      // if (!flag) return;
      let opt = {};
      opt = {
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      exportExcelNew("/acb/2.0/memberStatistic/exportPaymentTendency", opt);
    },
    // getTimeNow () {
    //   const startTime = new Date();
    //   const endTime = new Date();
    //   if (this.formInline.dateType == "day") {
    //     startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    //     endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    //   } else {
    //     startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
    //     endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
    //   }
    //   this.startTime = dateFormat(startTime, 'yyyy-MM-dd');
    //   this.endTime = dateFormat(endTime, 'yyyy-MM-dd');
    // },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.dateType === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
  },
  mounted() {
    this.searchNightParkList();
  },
  created() {
    // this.getTimeNow();
  },
  components: {
    PayChart,
    Carbinding,
    CarAccounted,
    // timeRangePick
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
