<template>
  <div class="leftColumn">
    <graphTitle title="会员线上支付占比">
      <el-tooltip
        placement="top"
        content="展示统计时间段内会员在线上产生的实收金额/订单数的占比情况"
      >
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="pieb" :style="{ height: '260px' }"></div>
    <p
      style="text-align: center; color: rgba(0, 0, 0, 0.65); font-size: 17px"
      v-if="this.number == 0"
    >
      会员收入总额：{{
        rightList.memberPayMoney ? (rightList.memberPayMoney / 100).toFixed(2) : 0 | toThousands
      }}元
    </p>
    <p
      style="text-align: center; color: rgba(0, 0, 0, 0.65); font-size: 17px"
      v-if="this.number == 1"
    >
      会员实收订单：{{
        rightList.onlineMemberPayCnt && rightList.offlineMemberPayCnt
          ? Number(rightList.onlineMemberPayCnt) + Number(rightList.offlineMemberPayCnt)
          : 0
      }}个
    </p>
    <div class="switch">
      <p :class="number == 0 ? 'add-style' : 'default-style'" @click="gold" style="cursor: pointer">收入金额</p>
      <p :class="number == 0 ? 'default-style1' : 'add-style1'" @click="pen" style="cursor: pointer">订单数量</p>
    </div>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    rightList: {
      // type: Array,
      default: function () {
        return {};
      },
    },
  },
  name: "BerthchargeNumber",
  components: {
    graphTitle,
  },
  filters: {
    toThousands(num) {
      return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  data() {
    return {
      number: 0,
      calculate: 0,
      Promptbox: "",
      PromptboxTwo: "",
      pie2: [
        { value: 0, name: "线上支付" },
        { value: 0, name: "线下支付" },
      ],
      pie3: [
        { value: 0, name: "线上支付" },
        { value: 0, name: "线下支付" },
      ],
    };
  },
  watch: {
    rightList: {
      handler(value) {
        if (value.length <= 0) {
          this.pie2 = [
            { value: 0, name: "线上支付" },
            { value: 0, name: "线下支付" },
          ];
          this.pie3 = [
            { value: 0, name: "线上支付" },
            { value: 0, name: "线下支付" },
          ];
          // this.calculate = 0;
          this.setPieB();
        } else {
          this.pie2 = [];
          this.pie3 = [];
          this.number = 0;
          // this.calculate = 0;
          // 金额
          this.pie2.push(
            { value: (value.onlineMemberPayMoney / 100).toFixed(2), name: "线上支付" },
            { value: (value.offlineMemberPayMoney / 100).toFixed(2), name: "线下支付" }
          );
          // this.calculate = parseInt(this.pie2[1].value / (this.pie2[0].value + this.pie2[1].value) * 100) + '%';
          // 笔数
          this.pie3.push(
            { value: value.onlineMemberPayCnt, name: "线上支付" },
            { value: value.offlineMemberPayCnt, name: "线下支付" }
          );
          this.setPieB();
        }
      },
      deep: true,
    },
  },
  methods: {
    gold() {
      this.number = 0;
      // this.calculate = 0;
      // if (this.pie2[0].value == '') {
      //   this.calculate = 0;
      // } else {
      //   this.calculate = parseInt(this.pie2[1].value / (this.pie2[0].value + this.pie2[1].value) * 100) + '%';
      // }
      // console.log(this.calculate)
      this.setPieB();
    },
    format(num) {
      return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    },
    pen() {
      this.number = 1;
      // this.calculate = 0;
      // if (this.pie3[0].value == '') {
      //   this.calculate = 0;
      // } else {
      //   this.calculate = parseInt(this.pie3[1].value / (this.pie3[0].value + this.pie3[1].value) * 100) + '%';
      // }
      // console.log(this.calculate)
      this.setPieB();
    },
    toThousands(num) {
      return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    setPieB() {
      let that = this;
      // let data = [
      //   {
      //     value: parseInt(100 * Math.random()),
      //     name: '未绑定用户'
      //   }, {
      //     value: parseInt(100 * Math.random()),
      //     name: '绑定用户'
      //   }
      // ]
      let option = {
        color: ["#FFA924", "#51A1F3"],
        // title: {
        //   text: '金额 | 笔数',
        //   left: 'left',
        //   top: '0',
        //   padding: [0, 25, 0, 0],
        //   textStyle: {
        //     fontSize: 14
        //   }
        // },
        tooltip: {
          trigger: "item",
          padding: [15, 15, 15, 30],
          // formatter: that.number == 0 ? that.Promptbox : that.number == 1 ? that.PromptboxTwo : that.Promptbox
          formatter: (params) => {
            if (this.number == 0) {
              let tipText = `<span>${params.marker}${params.name}</span>
								<p>
									<span style="position: absolute;left: 10px;"></span>支付金额${this.format(params.value)}(元)
								</p>
								<p>
									<span style="position: absolute;left: 10px;"></span>占比：${params.percent}%
								</p>
              `;
              return tipText;
            } else {
              let tipText = `<span>${params.marker}${params.name}</span>
								<p>
									<span style="position: absolute;left: 10px;"></span>支付订单${params.value}(个)
								</p>
								<p>
									<span style="position: absolute;left: 10px;"></span>占比：${params.percent}%
								</p>
              `;
              return tipText;
            }
          },
        },
        legend: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          data: [
            {
              name: "线上支付",
              icon: "circle",
            },
            {
              name: "线下支付",
              icon: "circle",
            },
          ],
        },
        // graphic: {
        //   type: 'text',
        //   left: 'center',
        //   top: '105px',
        //   z: 2,
        //   zlevel: 100,
        //   style: {
        //     text: this.calculate,
        //     fill: '#000',
        //     width: 100,
        //     height: 30,
        //     font: 'bolder 25px Microsoft YaHei'
        //   }
        // },
        series: [
          {
            center: ["50%", "45%"],
            name: "线上支付：",
            type: "pie",
            radius: ["65%", "80%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "30",
                  fontWeight: "bold",
                },
                formatter: (params) => {
                  return params.name;
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            // data: this.pie2
            data: that.number == 0 ? that.pie2 : that.number == 1 ? that.pie3 : that.pie2,
          },
        ],
      };
      let myChart = this.$echarts.init(document.getElementById("pieb"));
      myChart.setOption(option);
    },
  },
  created() {
    this.Promptbox = "{a}: {c}元 <br/>占比: {d}%";
    this.PromptboxTwo = "{a}: {c}条 <br/>占比: {d}%";
  },
  mounted() {
    this.setPieB();
  },
};
</script>
<style scoped>
.leftColumn {
  position: relative;
}
.switch {
  position: absolute;
  right: 20px;
  top: 10px;
  height: 32px;
  line-height: 32px;
}
.switch p {
  width: 76px;
  float: left;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
}
.switch .add-style {
  background: rgba(6,116,251,0.1200);
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #0474FB;
  color: #1C81FB;
}
.switch .default-style {
  background: #FFFFFF;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #C0C6CC;
  color: #333333;
}
.switch .add-style1 {
  background: rgba(6,116,251,0.1200);
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #0474FB;
  color: #1C81FB;
}
.switch .default-style1 {
  background: #FFFFFF;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #C0C6CC;
  color: #333333;
}
</style>
